.body {
    background-color: white;
    max-height: 1100px;
}

.header {
    background-color: #00BDF2;
    margin-top: 0px;
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.header a.button-27{
    width:200px;
    margin-right: 1%;
    font-size: 20px;
    background-color: rgb(0, 45, 114);
    text-decoration: underline;
}

.header img:first-child{
    width: 160px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 16px;
}

.left {
    float: left;
    background: white;
    width: 60%;
    height: 998px;
}

.right {
    float: right;
    background: #a2d2ff;
    width: 40%;
    height: 998px;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.centered2 {
    position: absolute;
    top: 60%;
    left: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.button-27 {
    appearance: none;
    background-color: rgb(0, 45, 114);
    /* border: 2px solid #1A1A1A; */
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    will-change: transform;
}

.button-27:disabled {
    pointer-events: none;
}

.button-27:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}
.quizQuestionContainer{
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    border-radius: 10px; 
    margin: 10px;
    background-color: white;
}
.button-27:active {
    box-shadow: none;
    transform: translateY(0);
}
/* .admin-form {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -70%);
    transform: translate(-50%, -70%);
} */

@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
.h1 {
    font-family: "Pacifico", cursive;
    font-size: 60px;
}

.login-input {
    width: 400px;
    height: 50px;
}
.ant-input {
    width: 400px;
    height: 50px;
    border: 1px solid #002d72;
}
input[id="adminLoginForm_password"].ant-input{
     height:40px;
}

@import url("https://fonts.googleapis.com/css2?family=Paytone+One&display=swap");
.signup-input {
    font-family: "Paytone One", sans-serif;
    font-size: 60px;
}

@import url("https://fonts.googleapis.com/css2?family=Castoro&display=swap");
.admin-dashboard-heading {
    font-family: "Castoro", serif;
    font-size: 30px;
    background-color: rgb(0, 45, 114);
    color: white;
    height: 50px !important;
    line-height: 50px !important;
}

/* .admin-dashboard-heading > * {
    height: 50px !important;
} */

.signup-body {
    height: 100vh;
}

.site-logo {
    float: left;
    color: white;
    font-size: 20px;
    margin-right: 40px;
}

.admin-sub-menu-header {
    font-family: "Castoro", serif;
    font-size: 20px;
    background-color: lightgrey !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* height: 5vh !important; */
    height: 44px !important;
}

.admin-sub-menu-header > * {
    margin-right: 20px;
}

.game-admin-main-view {
    display: flex;
    flex-direction: column;
    height: 80vh !important;
}

.game-admin-thin-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15%;
    width: 100%;
}

.game-admin-thick-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
    width: 100%;
}

.game-admin-thin-column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 25%;
}

.game-admin-thick-column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    background-color: lightgoldenrodyellow;
}

.ant-table {
    max-width: 20vw;
    max-height: 45vh;
}
a.button-27{
    background-color: rgb(198, 0, 126);
        width: 30%;
        min-height: initial;
}
 .centered.unauthPlaygroud {
    margin-top: 10%;
}
        
.centered.unauthPlaygroud p {            
    font-weight: 500;
}

.quizPlayers{
    width: 200px;
}
.quizGuy{
    width: 180px;
}   
textarea[id^="quizForm_text"]{
    width: 320px;
}
.closedQuestion, .openQuestion{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

}

input[id^="quizForm_option"] {
       width: 140px;
       height: 30px;

   }
input[id^="quizForm_hint"], input[id^="quizForm_imagePath"] { 
       width: 320px;
       height: 30px;
   
   }
        input[id^="quizForm_imagePath"] {
        width: 260px;
        }

.gamesMenuOptions {
    display: flex;
}
@media only screen and (max-width: 700px) {
.currentQuizzez{
    display: none;
}

}
@media only screen and (max-width: 630px) {
.h1{
    font-size: 40px;
}
.h1login{
    font-size: 28px;
}
.header{
    align-items: center;
}
.header button{
    margin-top: 0;
    width:180px;
}
.header NavLink{
    font-size: 14px;
}
.header img{
    width: 180px;
    margin-left: 0;
}
}
@media only screen and (max-width: 586px) {
    a.button-27 {
        width: 56%;
    }
    .centered.unauthPlaygroud {
         margin-top: 30%;
     }
     .ant-input {
        width: 300px;
     }
     .h1 {
        font-size: 32px;
    }
}
@media only screen and (max-width: 460px) {
.currentGames{
    display: none;
}
.admin-sub-menu-header>* {
    margin-right: 0;
}

}
@media only screen and (max-width: 450px) {

    .quizGuy,
    .quizPlayers {
        display: inline-block;
        width: 140px;
    }

    textarea[id^="quizForm_text"] {
        width: 300px;
    }
}
@media only screen and (max-width: 400px) {
.quizGuy, .quizPlayers {
        display: inline-block;
        width:100px;
    }
    .h1 {
        font-size: 26px;
    }

    .h1login {
        font-size: 22px;
    }

    .header {
        align-items: center;
    }

    .header button {
        margin-top: 0;
        width: 140px;
        font-size: 16px;
    }
    .header img {
        width: 140px;
    }
.ant-input{
    width:300px;
}
}
@media only screen and (max-width: 392px) {
.admin-sub-menu-header{
    padding-inline: 10px !important;
    justify-content: space-around;
}

.admin-sub-menu-header>* {
    margin-right: 0px;
}
.admin-dashboard-heading{
    padding-inline: 30px !important;
}
}
@media only screen and (max-width: 380px) {
    textarea[id^="quizForm_text"] {
            width: 260px;
            align-self: center;
        }
    input[id^="quizForm_option"] {
         width: 100px;
        }

        input[id^="quizForm_hint"] {
            width: 260px;
        }
       input[id^="quizForm_imagePath"] {
        width: 260px;
        }
        
}
@media only screen and (max-width: 340px) {
.admin-sub-menu-header button{
    font-size: 12px;
}
}
@media only screen and (max-width: 336px) {
    .centered.unauthPlaygroud p {
        font-size: 14px;
    }

    .header a.button-27 {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
    }
}

