.stats{
  display: flex;
  justify-content: space-around;
  width:70%;
}
h1{
  font-size:28px;
  text-align: center;
}
h2{
  text-align: center;
  font-size: 24px;
}
.result{
  margin-top: 20px;
    width: 80%;
    display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
}
.res{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.res img{
  margin-right:10px;
}
.res p{
  margin:0;
  font-weight: 500;
}
@media only screen and (max-width: 760px) {
  h1{
    font-size: 20px;
  }
  h2{
font-size: 18px;
  }
.stats{
  flex-direction: column;       
 align-items: center;

}
.result{
  width:100%;
}
}