.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(0, 45, 114);
    position: fixed;
    width: 100%;
    bottom: 0; 

}
.phantom{
    display: block;
    padding: 20px;
    height: 60px;
    width: 100%;
}
footer div{
    text-align: center;
}
footer img{
    width: 100px;
}
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
.footerSpan {
    font-family: "Pacifico", cursive;
    font-size: 30px;
}
@media only screen and (max-width: 840px) {
    .footerSpan{
        font-size:25px;
    }
    .footerRight{
        font-size: 14px;
    }
}

@media only screen and (max-width: 610px) {

footer img{
    width: 80px;
}
        .footerSpan {
            font-size: 18px;
        }
        .footerRight {
            font-size: 10px
         }
}
@media only screen and (max-width: 450px) {
    footer img {
            width: 80px;
        }
    .footerSpan {
        font-size: 14px;
    }

    .footerRight {
        font-size: 8px
    }
}
@media only screen and (max-width: 360px) {
    footer img {
        width: 50px;
    }

    .footerSpan {
        font-size: 12px;
    }

    .footerRight {
        font-size: 8px
    }
}
